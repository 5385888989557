import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Wrapper from '../styles/utilities/Wrapper';

const AdminPage = () => {
  useEffect(() => {
    window.location.href =
      window.location.hostname === 'localhost'
        ? 'http://localhost:3333'
        : 'https://kentuckybluecorn.sanity.studio/';
  }, []);
  return (
    <Layout>
      <Wrapper>
        <section
          style={{
            paddingTop: '100px',
          }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html:
                'If you can see this, then please go to <a href="https://kentuckybluecorn.sanity.studio/">https://kentuckybluecorn.sanity.studio/</a>',
            }}
          />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default AdminPage;
